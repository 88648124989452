import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template,
} from "../../page-components/projects/template"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import ProjectDetails from "../../images/projects/Serrata-Homes.yml"

const SerrataHomes = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query(
      $floorPlan: String = "/images/projects/serrata-homes/04 FLOOR PLANS/"
    ) {
      logo: file(relativePath: { eq: "SERRATA LOGO.png" }) {
        ...ImgL
      }
      siteDevImg: file(
        relativePath: { eq: "projects/serrata-homes/SERRATA SDP.png" }
      ) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "TownhouseUnits", num: "276" },
    { metric: "TypesofUnits", num: "3" },
    { metric: "TypicalLotArea", num: "32 - 40" },
    { metric: "TypicalFloorArea", num: "30 - 40" },
  ])
  const props: LocationTemplateProps = {
    title: "Serrata Homes",
    logo,
    location: "SAN JOSE DEL MONTE CITY, BULACAN",
    metrics,
    carouselImgs: [
      require("../../images/projects/serrata-homes/01 SERRATA TARA.jpg"),
      require("../../images/projects/serrata-homes/02 SERRATA FIONA.jpg"),
      require("../../images/projects/serrata-homes/03 SERRATA ALIA.jpg"),
    ],
    siteDev: {
      img: siteDevImg,
      txt:
        "Serrata is designed to be easily maneuvered around for the convenience of its users. Characterized by a grid-like layout, the townhouses are arranged in rows. The unit types are grouped together strategically to highlight the visual aesthetic of the community. Its users also enjoy its own private chapel & basketball for everyone’s use.",
    },
    amenities: {
      description:
        "Serrata enjoys its very own chapel for easy access and usage. The community is also equipped with its own basketball court for everyone's use.",
      items: [
        {
          name: "Chapel",
          description:
            "Find inner peace in the Chapel wherein you can set your intentions in a safe and sacred space.",
          img: require("../../images/projects/serrata-homes/amenities/01 SERRATA CHAPEL.jpg"),
        },
        {
          name: "Basketball Court",
          description:
            "Gain physical wellness and enjoy your free time in the Basketball Court.",
          img: require("../../images/projects/serrata-homes/amenities/02 SERRATA BASKETBALL COURT.jpg"),
        },
      ],
    },
    floorPlans: {
      description: `Serrata has four (4) types of units to offer. (1) Tara - stands at two-storeys with a typical lot area of 32sq.m; (2) Fiona 4M Frontage -stands at two-storeys with a typical lot area of 32sq.m; (3) Fiona 5M Frontage -stands at two-storeys with a typical lot area of 40sq.m; and (4) Alia - stands at two-storeys with a typical lot area of 40sq.m

      Our units give you the freedom and the power to create your home the way you want to. ** All Floor Plans below are for suggested layout only.`,
      items: [
        [
          { name: "Tara", fpImg: fpImg("01 SERRATA TARA FP"), pImg: fpImg("01 SERRATA TARA") },
          {
            name: "Fiona 4M",
            fpImg: fpImg("02 SERRATA FIONA 4M FP"),
            pImg: fpImg("02 SERRATA FIONA"),
          },
          {
            name: "Fiona 5M",
            fpImg: fpImg("03 SERRATA FIONA 5M FP"),
            pImg: fpImg("03 SERRATA FIONA"),
          },
          {
            name: "Alia",
            fpImg: fpImg("04 SERRATA ALIA FP"),
            pImg: fpImg("04 SERRATA ALIA"),
          },
        ],
      ],
    },
    projDetails: {
      ...ProjectDetails,
      description: "Serrata Homes is a community tucked away in San Jose Del Monte, Bulacan. It’s strategic location makes the community easily accessible to various landmarks around the area.",
    }
  }
  return <Template {...props} />
}

export default SerrataHomes
